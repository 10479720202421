<template>
<div class='widget-W0020V0001'>
  <div class="subs" :class="classPlacement">
    <div class="subs-header">
      <h2>Dagens fraværende</h2>
      <div class="subs-day-changer">
        <div class="subs-day-changer__button">
          <button class="subs-btn--white" :class="{'subs-btn--inactive': isDateToday}" @click="setToday">I
            dag
          </button>
          <button class="subs-btn--white" @click="setPrevDay"><</button>
          <button class="subs-btn--white" @click="setNextDay">></button>
        </div>
        <p>{{ dayDate }}</p>
      </div>
    </div>
    <div v-if="showErrorText">
      <h5 class="text--warning">{{errorText}}</h5>
    </div>
    <div v-if="!showErrorText && !isLoading && placement === 'narrow' && users.subs !== null" class="toogle-menu">
      <p>Vis lektioner og vikarer</p>
      <label class="switch">
        <input type="checkbox" v-model="showLessons">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="subs-is-loading" v-if="isLoading">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
    <div class="subs-flex" v-else>
      <h2 v-if="users.subs === null">Ingen fraværende</h2>
      <div class="sub" v-else v-for="user in users.subs" :key="user.user_id">
        <div class="sub-header">
          <div class="sub-header__img">
            <img :src="user.img"/>
          </div>
          <div class="sub-header__content">
            <h5>{{user.name}}</h5>
            <p v-for="absence in user.absence">{{absence.title}},
              {{showLocaleTime(absence.event_time_from)}} -
              {{showLocaleTime(absence.event_time_to)}}</p>
          </div>
        </div>
        <div v-if="!showLessons" class="text--link user-select-none" @click="setShowUserLessons(user)"><span
          v-if="!isUserInshowUserLessons(user.user_id)">Se</span><span v-else>Luk</span> lektioner og
          vikarer
        </div>
        <div class="sub-body"
             v-if="(showLessons || isUserInshowUserLessons(user.user_id)) && user && user.substitution && user.substitution.length > 0">
          <table class="sub-body__table">
            <thead>
            <tr>
              <td>Fag</td>
              <td>Klasse</td>
              <td>Vikar</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="event in user.substitution" :key="event.id">
              <td class="subs-event__title"><span>{{event.title}}</span>
                <div class="small-font">{{ showLocaleTime(event.event_time_from) }} - {{
                    showLocaleTime(event.event_time_to)}}
                </div>
              </td>
              <td><span v-for="(klass, key) in event.klasses" :key="key">{{klass}}</span></td>
              <td>
                <p>{{ event.substitute }}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    /* normal and secure */
    axios: Function,
    getAulaToken: Function,
    sessionUUID: String,
    placement: {
      type: String,
      default: 'narrow' // narrow, medium, full
    },
    moment: Function
  },
  data () {
    return {
      isLoading: false,
      showErrorText: false,
      errorText: '',
      date: this.moment(),
      substitution: null,
      users: {},
      aulaToken: null,
      showLessons: true,
      showUserLessons: []
    }
  },
  computed: {
    dayDate () {
      return this.moment(this.date).locale('da').format('dddd, DD. MMMM YYYY')
    },
    classPlacement () {
      return 'aula-' + this.placement
    },
    serverDate () {
      return this.moment(this.date).format('YYYY-MM-DD')
    },
    isDateToday () {
      return this.moment(this.date).format('YYYY-MM-DD') === this.moment().format('YYYY-MM-DD')
    }
  },
  watch: {
    date () {
      if (this.aulaToken != undefined) {
        this.getSubstitution()
      }
    },
    aulaToken: function () {
      if (this.aulaToken != undefined) {
        this.getSubstitution()
      }
    }
  },
  methods: {
    isUserInshowUserLessons (userid) {
      return this.showUserLessons.indexOf(userid) > -1
    },
    setShowUserLessons (user) {
      let idx = this.showUserLessons.indexOf(user.user_id)
      if (idx < 0) {
        this.showUserLessons.push(user.user_id)
      } else {
        this.showUserLessons.splice(idx, 1)
      }
    },
    showLocaleTime (date) {
      return this.moment(date).locale('da').format('LT')
    },
    setToday () {
      this.date = this.moment()
    },
    setPrevDay () {
      this.date = this.moment(this.date).subtract(1, 'days')
    },
    setNextDay () {
      this.date = this.moment(this.date).add(1, 'days')
    },
    getSubstitution () {
      this.isLoading = true
      const self = this
      this.axios.get('https://app.docendo.dk/aula/substitutes?date=' + this.serverDate,
        {
          headers: {'Authorization': self.aulaToken, AulaSessionUuid: self.sessionUUID},
          responseType: 'json'
        })
        .then(response => {
          this.isLoading = false
          if (response.data.response === 'error') {
            this.showErrorText = true
            this.errorText = response.data.message
          } else {
            this.showErrorText = false
            // JSON responses are automatically parsed.
            if (this.moment(response.data.date).format('YYYY-MM-DD') === this.moment(this.date).format('YYYY-MM-DD')) {
              self.users = response.data
            }
          }
        })
        .catch(e => {
          //some error handling
          this.isLoading = false
          this.showErrorText = true
          this.errorText = 'Der kan ikke skabes forbindelse til docendo'
        })
    }
  },
  created () {
    if (this.placement === 'narrow') {
      this.showLessons = false
    }
    this.aulaToken = this.getAulaToken()
  }
}
</script>

<style lang="scss">
.widget-W0020V0001 {
.subs h2 {
  color: #343a40;
  margin-bottom: 10px;
}

.subs.aula-narrow h2 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
}

.subs-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 55px;
  margin-right: -10px;
}

.subs.aula-narrow > .subs-flex {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 0;
}

.sub {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
  min-width: 310px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.subs.aula-narrow .sub {
  max-width: 100%;
  font-size: 0.85rem;
  padding: 10px;
}

.small-font {
  font-size: 12px;
}

@media only screen and (max-width: 992px) {
  .sub {
    background-color: #ffffff;
  }
}

@media only screen and (min-width: 992px) {
  .sub {
    background-color: #f8f9fa;
    max-width: 404px;
  }
}

.subs.aula-narrow .sub-body__table tr td {
  padding: 4px;
  max-width: 105px;
  overflow: hidden;
}

.sub h5 {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 900;
  margin: 0;
}

.sub-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 65px;
}

.sub-header__img > img {
  border-radius: 50%;
  margin-right: 20px;
}

.sub-body__table {
  padding: 10px;
  width: 100%;
  table-layout: fixed;
}

.sub-body__table tr {
  background-color: #e9eef1;
  margin-bottom: 5px;
}

.sub-body__table tbody tr:nth-child(even) {
  background-color: #f2f4f6;
}

.sub-body__table tr td {
  padding: 10px;
  font-size: 14px;
  vertical-align: middle;
}

.sub-body__table tr td p {
  margin: 0;
}

.sub-body__table tr td:first-child {
  min-width: 105px;
}

.sub-body__table thead tr {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #d9e3e9;
}

.subs-day-changer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #d9e3e9;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
}

.aula-narrow .subs-day-changer {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@media only screen and (max-width: 992px) {
  .subs-day-changer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.subs-day-changer > p {
  margin: 0 0 0 10px;
  text-transform: capitalize;
}

.aula-narrow .subs-day-changer > p {
  margin: 0 0 10px 0;
}

.subs-btn--white {
  color: #222350;
  background-color: #ffffff;
  -webkit-appearance: none;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  cursor: pointer;
  outline: none;
  padding: 5px 10px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.subs-btn--white:hover {
  background-color: #f6f7f8;
}

.subs-btn--white:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.subs-btn--white.subs-btn--inactive {
  background-color: #ffffff !important;
  opacity: 0.4;
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
  cursor: not-allowed;
}

.subs-event__title {
  word-break: break-all;
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #468AD7;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.text--warning {
  color: #b50050;
}

.toogle-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.toogle-menu > p {
  margin: 0 8px 0 0;
}

.text--link {
  color: #45b7c1;
  text-decoration: underline;
  cursor: pointer;
  border-top: 1px solid #d9e3e9;
  padding-top: 4px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  border-radius: 8px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #45b7c1;
}

input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #45b7c1;
  box-shadow: 0 0 1px #45b7c1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


}
</style>
