<template>
  <aula-modal
    ref="modalPopoverSingleEvent"
    :is-scroll-top="false"
    :hide-footer="true"
    :hide-back-drop="true"
    :css-class="'calendar-popover single-event'"
    labelled-by="calendar-popover-header"
    @closeClicked="closeClicked"
  >
    <template slot="header">
      <div class="popover-header position-sticky">
        <template v-if="!isMobile">
          <div id="calendar-popover-header" v-b-tooltip.hover class="popover-header-text truncate" :title="eventTitle">
            {{ eventTitle }}
          </div>
          <AulaButton
            class="popover-header_close_icon"
            variant="link"
            fill="clear"
            tabindex="0"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="closeClicked"
            @keydown.enter="closeClicked"
          >
            <Icon :name="iconClassEnum.CLOSE" />
          </AulaButton>
        </template>
        <template v-else>
          <i
            class="icon-Aula_arrow_new_item"
            tabindex="0"
            role="button"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="closeClicked"
            @keydown.enter="closeClicked"
          />
          <div id="calendar-popover-header" class="text">
            {{ 'CALENDAR_POPOVER_HEADER' | fromTextKey }}
          </div>
        </template>
      </div>
    </template>
    <template #headline>
      <HeadlineAlert v-if="lastReminderDateTime">
        {{ 'CALENDAR_EVENT_REMINDER_ALERT' | fromTextKey({ dateTime: lastReminderDateTime }) }}
      </HeadlineAlert>
    </template>
    <div class="popover-event-container d-flex flex-column h-100">
      <div class="popover-content scrollbar">
        <div :class="{ 'text-center': isMobile }">
          <div v-if="isMobile" class="popover-title">
            {{ eventTitle }}
          </div>
          <div class="popover-main-content">
            <template v-if="popoverEvent.repeating != null">
              {{ 'CALENDAR_LABEL_SERIES_EVENT' | fromTextKey }}<br />
            </template>
            <StartAndEndDateDifferences
              :start-date="popoverEvent.start"
              :end-date="popoverEvent.end"
              :is-all-day-event="popoverEvent.allDay"
              :show-end-format="showEndDateForEvent"
            />
            <br />
            <template v-if="!popoverEvent.allDay">
              {{ 'CALENDAR_LABEL_EVENT_CLOCK' | fromTextKey }}
              {{ moment(popoverEvent.start).format('HH:mm') }} -
              {{ moment(popoverEvent.end).format('HH:mm') }}
            </template>
            <template
              v-else-if="popoverEvent.type !== eventTypes.PRESENCE_HOLIDAY && popoverEvent.type !== eventTypes.HOLIDAY"
            >
              {{ 'CALENDAR_LABEL_EVENT_CLOCK' | fromTextKey }}
              {{ 'CALENDAR_LABEL_ALL_DAY_EVENT_TIME' | fromTextKey }}
            </template>
            <template v-if="isRepeatingEvent">
              <br />
              {{ 'CALENDAR_LABEL_EVENT_STARTS' | fromTextKey }}
              {{ startDate }}
              <template v-if="isNeverEnd"> {{ 'CALENDAR_LABEL_EVENT_NEVER_ENDS' | fromTextKey }}. </template>
              <template v-else>
                {{ 'CALENDAR_LABEL_EVENT_ENDS' | fromTextKey }}
                {{ lastOccurrenceDate }}.
              </template>
              {{ repeatingPattern }}.
            </template>
            <div
              v-if="
                [eventTypes.PERFORMANCE_MEETING, eventTypes.PARENTAL_MEETING, eventTypes.SCHOOL_HOME_MEETING].includes(
                  popoverEvent.type
                )
              "
            >
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_LOCATION' | fromTextKey }}:</span>
              {{ timeSlotLocation }}
            </div>
            <div v-else-if="locations">
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_LOCATIONS' | fromTextKey }}:</span>
              {{ locations }}
            </div>
            <div
              v-if="
                (popoverEvent.additionalResources && popoverEvent.additionalResources.length > 0) ||
                popoverEvent.additionalResourceText
              "
            >
              <template v-if="meetingPlace">
                <p class="m-0">
                  <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_MEETING_PLACE' | fromTextKey }}:</span>
                  {{ meetingPlace }}
                </p>
              </template>
              <template
                v-if="
                  hasResources &&
                  (popoverEvent.type !== eventTypes.LESSON ||
                    (popoverEvent.type === eventTypes.LESSON && profile.role === portalRoles.EMPLOYEE))
                "
              >
                <span class="font-weight-bold">{{ 'CALENDAR_LABEL_RESOURCE_TYPES' | fromTextKey }}:</span>
                <template v-for="(resource, index) in popoverEvent.additionalResources">
                  <template v-if="resource.resourceCategory.resourceType !== resourceTypes.LOCATION">
                    {{ resource.name }} ({{ resource.institutionName }})<span
                      v-if="
                        popoverEvent.additionalResources.filter(
                          location => location.resourceCategory.resourceType !== resourceTypes.LOCATION
                        ).length >
                        index + 1
                      "
                      :key="index"
                      >,
                    </span>
                  </template>
                </template>
              </template>
            </div>
            <div v-if="popoverEvent.creator != null && popoverEvent.type !== eventTypes.PRESENCE_HOLIDAY">
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_CREATOR' | fromTextKey }}:</span>
              {{ popoverEvent.creator | displayProfileNameWithMetadata }}
            </div>
            <div
              v-if="
                popoverEvent.type === eventTypes.PARENTAL_MEETING ||
                popoverEvent.type === eventTypes.SCHOOL_HOME_MEETING
              "
            >
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_CONCERNING' | fromTextKey }}:</span>
              {{ getConcerningNames }}
            </div>
            <div v-if="canShowOldEndTimes && haveOldEndTimes" class="mt-3">
              <span>{{ 'CALENDAR_LABEL_MOVED_FROM' | fromTextKey }}:</span><br />
              <StartAndEndDateDifferences
                :start-date="popoverEvent.oldStartDateTime"
                :end-date="popoverEvent.oldEndDateTime"
                :is-all-day-event="popoverEvent.oldAllDay"
                :popover-event="popoverEvent"
              /><br />
              {{ 'CALENDAR_LABEL_EVENT_CLOCK' | fromTextKey }}
              <StartAndEndDateDifferences
                :start-date="popoverEvent.oldStartDateTime"
                :end-date="popoverEvent.oldEndDateTime"
                :is-all-day-event="popoverEvent.oldAllDay"
                :format="'HH:mm'"
              />
            </div>
            <p v-if="organizerNames.length > 0" class="m-0">
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_ORGANIZERS' | fromTextKey }}:</span>
              {{ organizerNames }}
            </p>
            <div v-if="popoverEvent.type === eventTypes.LESSON">
              <div v-if="pedagogueName.length">
                <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_PEDAGOGUE' | fromTextKey }}:</span>
                {{ pedagogueName.join(', ') }}
              </div>
              <div v-if="teacherName.length">
                <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_TEACHER' | fromTextKey }}:</span>
                {{ teacherName.join(', ') }}
              </div>
              <div v-if="helpTeacher.length">
                <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_HELP_TEACHER' | fromTextKey }}:</span>
                {{ helpTeacher.join(', ') }}
              </div>
              <div v-if="substituteName.length">
                <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_SUBSTITUTE' | fromTextKey }}:</span>
                {{ substituteName.join(', ') }}
              </div>
            </div>
            <div v-if="popoverEvent.type === eventTypes.LESSON">
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_CLASS' | fromTextKey }}:</span>
              {{ className }}
            </div>
            <div v-if="popoverEvent.responseDeadline">
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_REQUEST_DEADLINE' | fromTextKey }}:</span>
              {{ moment(popoverEvent.responseDeadline).format('dddd [d.] D[.] MMM[.]') }}
            </div>
            <div v-if="hasMaximumNumberOfParticipants">
              <span class="font-weight-bold">{{ 'CALENDAR_LABEL_EVENT_AVAILABLE_SEATS' | fromTextKey }}:</span>
              {{
                'CALENDAR_EVENT_PARTICIPANTS'
                  | fromTextKey({
                    available: numberOfAvailableSlots,
                    max: popoverEvent.maximumNumberOfParticipants,
                  })
              }}
            </div>
          </div>
          <template v-if="eventInvitees.length > 0">
            <div class="popover-line" />
            <CalendarEventInvitees
              :invitees="eventInvitees"
              :show-participant-information="popoverEvent.doRequestNumberOfParticipants"
              :show-send-reminder="canSendReminder"
            />
          </template>
          <div class="popover-line" />
          <div v-if="popoverEvent.type !== eventTypes.LESSON">
            <label class="pr-4">{{ 'CALENDAR_LABEL_EVENT_PRIVATE' | fromTextKey }}</label>
            <el-switch :value="popoverEvent.private" disabled />
            <div class="popover-line" />
          </div>
          <b-row v-if="popoverEvent.type === eventTypes.LESSON" class="text-left">
            <b-col>
              <label class="mb-0">{{ 'CALENDAR_LABEL_EVENT_MESSAGE_CLASS' | fromTextKey }}</label>
              <div class="popover-text-editor" v-html="popoverEvent.lesson.noteToClass.html" />
              <template v-if="profile.role === portalRoles.EMPLOYEE && popoverEvent.lesson.noteToTeacher != null">
                <label class="mb-0 mt-3">{{ 'CALENDAR_LABEL_EVENT_MESSAGE_TEACHER' | fromTextKey }}</label>
                <div class="popover-text-editor" v-html="popoverEvent.lesson.noteToTeacher.html" />
              </template>
              <template v-if="profile.role === portalRoles.EMPLOYEE && popoverEvent.lesson.noteToSubstitute != null">
                <label class="mb-0 mt-3">{{ 'CALENDAR_LABEL_EVENT_MESSAGE_SUBSTITUTE' | fromTextKey }}</label>
                <div class="popover-text-editor" v-html="popoverEvent.lesson.noteToSubstitute.html" />
              </template>
            </b-col>
          </b-row>
          <b-row
            v-if="
              popoverEvent.description != null &&
              popoverEvent.description.html != null &&
              (popoverEvent.type !== eventTypes.LESSON ||
                (popoverEvent.type === eventTypes.LESSON &&
                  popoverEvent.lesson.lessonStatus === lessonStatus.SUBSTITUTE))
            "
            class="text-left"
          >
            <b-col>
              <label class="mb-0 mt-3">{{ 'CALENDAR_LABEL_EVENT_DESCRIPTION' | fromTextKey }}</label>
              <div class="popover-text-editor" v-html="popoverEvent.description.html" />
            </b-col>
          </b-row>
          <br />
          <attachment-thumbnails
            :attachments="popoverEvent.attachments"
            :parent="parentTypes.CALENDAR"
            @attachmentClicked="attachmentClicked"
          />
          <attachment-list
            :attachments="popoverEvent.attachments"
            :show-media="true"
            @openDocumentDrawer="openDocumentDrawer"
          />
        </div>
      </div>
      <div v-if="isRequiredParticipantSeats" class="popover-participant-seats popover-participant-seats-variables">
        <div class="seat-select adult">
          <div>{{ 'CALENDAR_LABEL_PARTICIPANT_SEATS_ADULT' | fromTextKey }}</div>
          <AulaSelect
            v-model="numberOfAdultParticipants"
            :class="{
              'red-border': isMissingNumberOfParticipants || (!hasEnoughSlots && hasSpecifiedNumberOfParticipants),
            }"
            :disabled="isEventOccupied"
            @change="onSelectedNumberOfParticipants"
          >
            <AulaOption v-for="(option, index) in participantSlots" :key="index" :label="option" :value="option" />
          </AulaSelect>
        </div>
        <div class="seat-select child">
          <div>{{ 'CALENDAR_LABEL_PARTICIPANT_SEATS_CHILD' | fromTextKey }}</div>
          <AulaSelect
            v-model="numberOfChildParticipants"
            :class="{
              'red-border': isMissingNumberOfParticipants || (!hasEnoughSlots && hasSpecifiedNumberOfParticipants),
            }"
            :disabled="isEventOccupied"
            @change="onSelectedNumberOfParticipants"
          >
            <AulaOption v-for="(option, index) in participantSlots" :key="index" :label="option" :value="option" />
          </AulaSelect>
        </div>
      </div>
      <div class="popover-alerts">
        <b-alert class="mt-0 mb-1" variant="danger" :show="isEventOccupied">
          {{ 'CALENDAR_NO_MORE_SEATS' | fromTextKey }}
        </b-alert>
        <b-alert class="mt-0 mb-1" variant="danger" :show="!hasEnoughSlots && hasSpecifiedNumberOfParticipants">
          {{
            'CALENDAR_NOT_ENOUGH_REMAINING_SLOTS'
              | fromTextKey({
                numberOfRemainingSlots,
              })
          }}
        </b-alert>
        <b-alert class="mt-0 mb-1" variant="danger" :show="isMissingNumberOfParticipants">
          {{ 'CALENDAR_MISSING_NUMBER_OF_PARTICIPANTS' | fromTextKey }}
        </b-alert>
      </div>
      <div class="popover-footer position-sticky">
        <CalendarEventFooterResponseButtons
          v-if="currentAttendee != null && canResponseToEvent"
          :drop-up="isMobile"
          :responded-type="currentAttendee.responseType"
          :disabled-accept="isDisabledAccept"
          :show-tentative-response="profile.role === portalRoles.EMPLOYEE && !isMeeting"
          :show-repeating-event-options="canAnswerForSeries"
          :show-repeating-occurrence-option="canRespondToOccurrence"
          :show-repeating-series-option="canRespondToSeries"
          :show-update-answer="isUpdatingAnswer"
          @onRespondToCurrentEvent="respondToEvent($event, true)"
          @onRespondToRepeatingSeries="respondToEvent"
        />
        <CalendarEventFooterAdministerButtons
          v-if="canAdministerEvent"
          :drop-up="isMobile"
          :show-edit="canEditEvent"
          :show-delete="canDeleteEvent"
          :show-repeating-event-options="isRepeatingEvent"
          :show-repeating-occurrence-option="canAdministerRepeatingOccurrence"
          @onEditCurrentEvent="editEvent"
          @onDeleteCurrentEvent="eventToDelete(popoverEvent, true)"
          @onEditRepeatingSeries="editRepeatingSeries"
          @onDeleteRepeatingSeries="eventToDelete(popoverEvent)"
        />
        <AulaButtons v-if="canUpdateResponseToEvent" class="d-flex justify-content-center">
          <AulaButton class="text-center" variant="link" @click="changeTimeSlotResponse()">
            <span class="button-text font-weight-bold">{{ 'CALENDAR_CHANGE_TIME' | fromTextKey }}</span>
          </AulaButton>
        </AulaButtons>
      </div>
    </div>
    <media-details
      v-if="isShown && ($route.name === 'viewMediaEvent' || $route.name === 'group-calendar-view-media-event')"
      :album="
        popoverEvent.attachments != null && popoverEvent.attachments.length > 0
          ? popoverEvent.attachments.filter(att => att.media != null).map(att => att.media)
          : []
      "
      :parent="parent === parentTypes.GROUP ? parentTypes.GROUP_CALENDAR : parentTypes.CALENDAR"
    />
  </aula-modal>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import AttachmentList from '../../../shared/components/AttachmentList';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { eventTypeEnum } from '../../../shared/enums/eventTypeEnum';
import { eventResponseStatus } from '../../../shared/enums/eventResponseStatus';
import { groupTypes } from '../../../shared/enums/groupTypes';
import { teacherTypes } from '../../../shared/enums/teacherTypes';
import { resourceTypes } from '../../../shared/enums/resourceTypes';
import { lessonStatus } from '../../../shared/enums/lessonStatus';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { eventRepeatPatterns } from '../../../shared/enums/eventRepeatPatterns';
import AttachmentThumbnails from '../../../shared/components/AttachmentThumbnails';
import MediaDetails from '../gallery/MediaDetail';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { convertWeekdayMask } from './convertWeekdayMask';
import { calendarUtil } from '../../../shared/utils/calendarUtil.ts';
import StartAndEndDateDifferences from '../../../shared/components/StartAndEndDateDifferences';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import CalendarEventFooterResponseButtons from '../../../shared/PageFragments/CalendarEvent/CalendarEventFooterResponseButtons';
import CalendarEventFooterAdministerButtons from '../../../shared/PageFragments/CalendarEvent/CalendarEventFooterAdministerButtons';
import { format, isSame } from '../../../shared/utils/dateUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import CalendarEventInvitees from '../../../shared/PageFragments/CalendarEvent/CalendarEventInvitees';
import AulaButton from '../../../shared/components/AulaButton.vue';
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import HeadlineAlert from '../../../shared/components/HeadlineAlert.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { RepeatingPattern } from '../../business/calendar/repeatingPattern';
import { DateTimeUtil } from '../../../shared/utils/dateTimeUtil';

export default {
  components: {
    Icon,
    HeadlineAlert,
    AulaButtons,
    AulaButton,
    CalendarEventInvitees,
    CalendarEventFooterAdministerButtons,
    CalendarEventFooterResponseButtons,
    StartAndEndDateDifferences,
    AttachmentThumbnails,
    AttachmentList,
    MediaDetails,
  },
  inject: {
    getCanHandleOthersEvents: { from: providerKeyTypes.canHandleOthersEvents, default: () => () => false },
    onDeleteEventClicked: { from: providerKeyTypes.onDeleteEventClicked, default: () => () => null },
    getExistingSharedCalendars: { from: providerKeyTypes.existingSharedCalendars, default: () => () => [] },
    getOccurrenceDateTime: { from: providerKeyTypes.occurrenceDateTime, default: () => () => null },
  },
  props: {
    popoverEvent: { type: Object, default: () => {} },
    parent: { type: String, default: parentTypes.PROFILE },
    isShowMoreDetails: { type: Boolean, default: false },
    relatedNotification: { type: Object, default: null },
    timeSlotId: { type: Number, default: null },
  },
  data: function () {
    return {
      convertWeekdayMask: convertWeekdayMask,
      eventRepeatPatterns: eventRepeatPatterns,
      portalRoles: portalRoles,
      parentTypes: parentTypes,
      eventTypes: eventTypeEnum,
      eventResponseStatus: eventResponseStatus,
      resourceTypes: resourceTypes,
      lessonStatus: lessonStatus,
      moment: moment,
      inviteeNames: [],
      isShown: false,
      numberOfAdultParticipants: null,
      numberOfChildParticipants: null,
      participantSlots: Array.from(Array(21).keys()),
      isMissingNumberOfParticipants: false,
      hasNumberOfParticipantsChanged: false,
    };
  },
  computed: {
    isUpdatingAnswer() {
      return this.hasNumberOfParticipantsChanged && this.currentAttendee.responseType === eventResponseStatus.ACCEPTED;
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      institutions: types.GET_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
      group: types.GET_ACTIVE_GROUP,
      children: types.GET_CHILDREN,
      myDelegatedAccesses: types.GET_MY_DELEGATED_ACCESSES,
      calendarActiveInstProfileId: types.GET_CALENDAR_ACTIVE_INST_PROFILE_ID,
      notifications: types.GET_NOTIFICATIONS,
      hasPermission: types.HAS_PERMISSION,
      currentEvent: types.GET_CURRENT_EVENT,
    }),
    repeatingPattern() {
      return RepeatingPattern.getPattern(
        this.popoverEvent.repeating.pattern,
        '',
        this.popoverEvent.repeating.weekdayMask,
        this.popoverEvent.repeating.dayInMonth,
        this.popoverEvent.repeating.interval
      );
    },
    canSendReminder() {
      if (this.isCommunicationBlock || this.isRepeatingEvent) {
        return false;
      }

      return this.isActiveProfileEventCreator || this.isCoOrganizer;
    },
    lastReminderDateTime() {
      if (!this.currentEvent?.lastReminderDateTime || (!this.isActiveProfileEventCreator && !this.isCoOrganizer)) {
        return null;
      }
      return format(this.currentEvent.lastReminderDateTime, dateFormatEnum.LONG_DATE_TIME_WITH_DATE_PREFIX);
    },
    canUpdateResponseToEvent() {
      return this.popoverEvent?.timeSlot?.canUpdateResponseToEvent && !this.popoverEvent.isDeadlineExceeded;
    },
    canAdministerRepeatingOccurrence() {
      return this.occurrenceDateTime != null;
    },
    isDisabledAccept() {
      return this.isEventOccupied || !this.hasEnoughSlots || !this.hasSpecifiedNumberOfParticipants;
    },
    hasSpecifiedNumberOfParticipants() {
      if (!this.isRequiredParticipantSeats) {
        return true;
      }

      return this.numberOfAdultParticipants !== 0 || this.numberOfChildParticipants !== 0;
    },
    isRequiredParticipantSeats() {
      return (
        !this.isRepeatingEvent &&
        this.canResponseToEvent &&
        this.popoverEvent.doRequestNumberOfParticipants &&
        [portalRoles.GUARDIAN, portalRoles.CHILD, portalRoles.OTP].includes(this.profile.role) &&
        !!this.currentAttendee
      );
    },
    locations() {
      const locations = [];
      if (this.popoverEvent.primaryResourceText) {
        locations.push(this.popoverEvent.primaryResourceText);
      }
      if (this.popoverEvent.primaryResource) {
        locations.push(
          `${this.popoverEvent.primaryResource.name} (${this.popoverEvent.primaryResource.institutionName})`
        );
      }
      locations.push(...this.additionalLocations.map(location => `${location.name} (${location.institutionName})`));
      return locations.join(', ');
    },
    meetingPlace() {
      return this.popoverEvent.additionalResourceText;
    },
    isNeverEnd() {
      return this.popoverEvent.repeating.maxDate == null && this.popoverEvent.repeating.occurenceLimit == null;
    },
    startDate() {
      return this.popoverEvent.allDay
        ? DateTimeUtil.formatUtcDate(
            this.popoverEvent.repeating.originalStartDateTime,
            dateFormatEnum.FULL_DATE_WITH_WEEKDAY
          )
        : DateTimeUtil.formatDate(
            this.popoverEvent.repeating.originalStartDateTime,
            dateFormatEnum.FULL_DATE_WITH_WEEKDAY
          );
    },
    lastOccurrenceDate() {
      return this.popoverEvent.isAllDayEvent
        ? DateTimeUtil.formatDate(
            this.popoverEvent.repeating?.lastOccurrenceDate,
            dateFormatEnum.FULL_DATE_WITH_WEEKDAY
          )
        : DateTimeUtil.formatUtcDate(
            this.popoverEvent.repeating?.lastOccurrenceDate,
            dateFormatEnum.FULL_DATE_WITH_WEEKDAY
          );
    },
    occurrenceDateTime() {
      if (!this.relatedNotification) {
        return this.getOccurrenceDateTime();
      }
      if (!this.relatedNotification.occurrenceDateTime) {
        return null;
      }
      return format(this.relatedNotification.occurrenceDateTime, dateFormatEnum.COMPLETE_DATE);
    },
    isRepeatingEvent() {
      return this.popoverEvent.repeating != null;
    },
    canRespondToOccurrence() {
      if (!this.relatedNotification) {
        return true;
      }
      return [
        notificationEventTypes.INVITED_TO_SINGLE_OCCURRENCE_OF_EVENT,
        notificationEventTypes.INVITED_TO_EXCEPTION_EVENT,
      ].includes(this.relatedNotification.notificationEventType);
    },
    canRespondToSeries() {
      if (!this.relatedNotification) {
        return true;
      }
      return [
        notificationEventTypes.EVENT_CHANGED_RESPONSE_REQUIRED,
        notificationEventTypes.INVITED_TO_REPEATING_EVENT,
      ].includes(this.relatedNotification.notificationEventType);
    },
    canAnswerForSeries() {
      return this.popoverEvent.canAnswerForSeries === true;
    },
    existingSharedCalendars() {
      return this.getExistingSharedCalendars();
    },
    isMeeting() {
      return [
        eventTypeEnum.PERFORMANCE_MEETING,
        eventTypeEnum.PARENTAL_MEETING,
        eventTypeEnum.SCHOOL_HOME_MEETING,
      ].includes(this.popoverEvent.type);
    },
    isTimeSlotEvent() {
      return this.isMeeting && this.timeSlotId != null;
    },
    timeSlotLocation() {
      const selectedTimeSlot = this.popoverEvent.timeSlot?.timeSlots.find(slot => slot.id === this.timeSlotId);
      if (!selectedTimeSlot) {
        return '';
      }
      if (selectedTimeSlot.primaryResource) {
        return selectedTimeSlot.primaryResource.name;
      }
      return selectedTimeSlot.primaryResourceText;
    },
    hasMaximumNumberOfParticipants() {
      return this.popoverEvent.maximumNumberOfParticipants != null;
    },
    numberOfAvailableSlots() {
      if (!this.hasMaximumNumberOfParticipants) {
        return Infinity;
      }
      const actualNumberOfParticipants = this.popoverEvent.actualNumberOfParticipants || 0;
      return Math.max(this.popoverEvent.maximumNumberOfParticipants - actualNumberOfParticipants, 0);
    },
    numberOfRemainingSlots() {
      let numberOfRemainingSlots = Infinity;
      if (this.popoverEvent.maximumNumberOfParticipants != null) {
        numberOfRemainingSlots =
          this.popoverEvent.maximumNumberOfParticipants - this.popoverEvent.actualNumberOfParticipants;
      }

      if (this.currentAttendee?.responseType === eventResponseStatus.ACCEPTED) {
        const { numberOfAdultParticipants, numberOfChildParticipants } = this.currentAttendee;
        numberOfRemainingSlots = numberOfRemainingSlots + numberOfAdultParticipants + numberOfChildParticipants;
      }
      return Math.max(numberOfRemainingSlots, 0);
    },
    hasEnoughSlots() {
      if (!this.isRequiredParticipantSeats || this.isEventOccupied || !this.hasNumberOfParticipantsChanged) {
        return true;
      }
      const adultParticipants = this.numberOfAdultParticipants || 0;
      const childParticipants = this.numberOfChildParticipants || 0;
      return this.numberOfRemainingSlots - adultParticipants - childParticipants >= 0;
    },
    isEventOccupied() {
      const { actualNumberOfParticipants, maximumNumberOfParticipants } = this.popoverEvent;
      if (
        this.isCoOrganizer ||
        actualNumberOfParticipants == null ||
        maximumNumberOfParticipants == null ||
        !this.currentAttendee ||
        this.currentAttendee.responseType === eventResponseStatus.ACCEPTED
      ) {
        return false;
      }

      return actualNumberOfParticipants >= maximumNumberOfParticipants;
    },
    eventInvitees() {
      return this.getAttendeesName();
    },
    showEndDateForEvent() {
      if (this.popoverEvent.end == null) {
        return false;
      }

      if (
        this.popoverEvent.type === eventTypeEnum.PRESENCE_HOLIDAY ||
        this.popoverEvent.type === eventTypeEnum.HOLIDAY
      ) {
        return true;
      }

      return this.popoverEvent.repeating == null;
    },
    canShowOldEndTimes() {
      if (this.popoverEvent.repeating || this.isMeetingEvent) {
        return false;
      }

      return this.currentAttendee?.responseType === eventResponseStatus.WAITING;
    },
    haveOldEndTimes() {
      return this.popoverEvent.oldEndDateTime != null && this.popoverEvent.oldStartDateTime;
    },
    additionalLocations() {
      let additionalResources = [];
      if (this.popoverEvent.additionalResources) {
        additionalResources = this.popoverEvent.additionalResources.filter(
          resource => resource.resourceCategory.resourceType === resourceTypes.LOCATION
        );
      }
      return additionalResources;
    },
    canHandleOthersEvents() {
      return this.getCanHandleOthersEvents();
    },
    canAdministerEvent() {
      if (this.relatedNotification != null) {
        return false;
      }

      if (this.timeSlotId) {
        return false;
      }

      if (this.isActiveProfileEventCreator || this.isCoOrganizer || this.canHandleOthersEvents) {
        return true;
      }

      if (this.isChildProfileAndHoliday) {
        return false;
      }

      return this.isLesson || this.isChildrenHoliday || this.isAddedOnlyInSchoolCalendar;
    },
    canResponseToEvent() {
      const isEventCreator = this.popoverEvent.creator?.id == this.calendarActiveInstProfileId;
      if (this.popoverEvent.isDeadlineExceeded || this.isMeetingEvent || isEventCreator) {
        return false;
      }

      const currentAttendeeRole = this.currentAttendee?.instProfile?.role;
      if (currentAttendeeRole !== undefined && currentAttendeeRole !== portalRoles.CHILD) {
        return true;
      }
      return (
        (this.popoverEvent.className == null || !this.popoverEvent.className.includes('old-event')) &&
        this.popoverEvent.timeSlot == null
      );
    },
    isCoOrganizer() {
      if (this.popoverEvent == null || !Array.isArray(this.popoverEvent.coOrganizers)) {
        return false;
      }
      return this.popoverEvent.coOrganizers.some(({ instProfile }) => this.canHandleEventProfile(instProfile));
    },
    organizerNames() {
      const organizers = this.popoverEvent.coOrganizers || [];

      return organizers
        .map(organizer => this.$options.filters.displayProfileNameWithMetadata(organizer.instProfile))
        .join(', ');
    },
    eventTitle() {
      let title = this.$options.filters.firstLetterUppercase(this.popoverEvent.title);
      if (this.popoverEvent.extendedProps) {
        if (this.popoverEvent.extendedProps.type === eventTypeEnum.BIRTHDAY) {
          title = this.$options.filters.fromTextKey('CALENDAR_TOOLBAR_EVENT_TYPES_ENUM_birthday') + ': ' + title;
        }
      }
      return title;
    },
    isActiveProfileEventCreator() {
      const id = this.calendarActiveInstProfileId;
      // Check if the active calendar profile relates to active profile context
      const activeProfile = this.institutions.find(inst => inst.institutionProfileId == id) != null;
      // Check if the active profile context is creator of the event
      const creatorProfile =
        this.institutions.find(
          inst => this.popoverEvent.creator != null && inst.institutionProfileId == this.popoverEvent.creator.id
        ) != null;
      // Check if profile context is the active profile AND the creator
      const isProfileContextCreator = activeProfile && creatorProfile;
      // Check if the creator of the event is the active profile
      const isActiveProfileCreator =
        this.popoverEvent.creator != null && this.popoverEvent.creator.id == this.calendarActiveInstProfileId;

      return (isProfileContextCreator || isActiveProfileCreator) && this.popoverEvent.type != this.eventTypes.BIRTHDAY;
    },
    isAddedOnlyInSchoolCalendar() {
      return (
        this.popoverEvent.hideInOwnCalendar &&
        this.hasPermission(permissionEnum.CREATE_EVENTS_ONLY_IN_INSTITUTION_CALENDAR)
      );
    },
    isLesson() {
      return (
        this.profile.role == this.portalRoles.EMPLOYEE &&
        this.popoverEvent.type == this.eventTypes.LESSON &&
        this.popoverEvent.lesson != null &&
        this.popoverEvent.lesson.lessonStatus != lessonStatus.CANCELLED
      );
    },
    isChildrenHoliday() {
      return (
        this.children.find(child => this.popoverEvent.creator != null && child.id == this.popoverEvent.creator.id) !=
          null &&
        (this.popoverEvent.type == this.eventTypes.HOLIDAY ||
          this.popoverEvent.type == this.eventTypes.PRESENCE_HOLIDAY)
      );
    },
    isChildProfileAndHoliday() {
      return (
        this.profile.role == portalRoles.CHILD &&
        (this.popoverEvent.type == eventTypeEnum.HOLIDAY || this.popoverEvent.type == eventTypeEnum.PRESENCE_HOLIDAY)
      );
    },
    hasResources() {
      for (const resource of this.popoverEvent.additionalResources) {
        if (resource.resourceCategory.resourceType !== resourceTypes.LOCATION) {
          return true;
        }
      }
      return false;
    },
    className() {
      let className = '';
      if (this.popoverEvent.type == eventTypeEnum.LESSON) {
        if (this.popoverEvent.invitedGroups != null) {
          const mainGroups = this.popoverEvent.invitedGroups
            .filter(group => group.mainGroup || group.type == groupTypes.UNI)
            .map(group => group.name);
          if (mainGroups.length > 0) {
            className = mainGroups.join(', ');
          }
        }
      }
      return className;
    },
    teacherName() {
      const teacher = [];
      if (this.popoverEvent.type == eventTypeEnum.LESSON) {
        if (this.popoverEvent.lesson != null && this.popoverEvent.lesson.participants != null) {
          for (const participant of this.popoverEvent.lesson.participants) {
            if (participant.participantRole == teacherTypes.PRIMARY) {
              teacher.push(
                participant.participantProfile
                  ? Vue.filter('displayProfileNameWithMetadata')(participant.participantProfile)
                  : participant.teacherName
              );
            }
          }
        }
      }
      return teacher;
    },
    pedagogueName() {
      const teacher = [];
      if (this.popoverEvent.type == eventTypeEnum.LESSON) {
        if (this.popoverEvent.lesson != null && this.popoverEvent.lesson.participants != null) {
          for (const participant of this.popoverEvent.lesson.participants) {
            if (participant.participantRole == teacherTypes.PEDAGOGUE) {
              teacher.push(
                participant.participantProfile
                  ? Vue.filter('displayProfileNameWithMetadata')(participant.participantProfile)
                  : participant.teacherName
              );
            }
          }
        }
      }
      return teacher;
    },
    helpTeacher() {
      const teacher = [];
      if (this.popoverEvent.type == eventTypeEnum.LESSON) {
        if (this.popoverEvent.lesson != null && this.popoverEvent.lesson.participants != null) {
          for (const participant of this.popoverEvent.lesson.participants) {
            if (participant.participantRole == teacherTypes.HELP) {
              teacher.push(
                participant.participantProfile
                  ? Vue.filter('displayProfileNameWithMetadata')(participant.participantProfile)
                  : participant.teacherName
              );
            }
          }
        }
      }
      return teacher;
    },
    substituteName() {
      const teacher = [];
      if (this.popoverEvent.type == eventTypeEnum.LESSON) {
        if (this.popoverEvent.lesson != null && this.popoverEvent.lesson.participants != null) {
          for (const participant of this.popoverEvent.lesson.participants) {
            if (participant.participantRole == teacherTypes.SUBSTITUTE) {
              teacher.push(
                participant.participantProfile
                  ? Vue.filter('displayProfileNameWithMetadata')(participant.participantProfile)
                  : participant.teacherName
              );
            }
          }
        }
      }
      return teacher;
    },
    getConcerningNames() {
      const concerningNames = [];
      if (this.relatedNotification != null && this.relatedNotification.relatedChildInstitutionProfileId != null) {
        const concerningProfile = this.popoverEvent.invitees.find(
          inv => inv.instProfile.id == this.relatedNotification.relatedChildInstitutionProfileId
        );
        if (concerningProfile != null) {
          concerningNames.push(Vue.filter('displayProfileNameWithMetadata')(concerningProfile.instProfile));
        }
      } else if (
        this.popoverEvent.invitees != null &&
        (this.popoverEvent.type == eventTypeEnum.PARENTAL_MEETING ||
          this.popoverEvent.type == eventTypeEnum.SCHOOL_HOME_MEETING)
      ) {
        if (this.popoverEvent.timeSlot != null) {
          for (const timeSlot of this.popoverEvent.timeSlot.timeSlots) {
            for (const answer of this.getRelevantAnswers(timeSlot)) {
              const timeSlotIndex = timeSlot.timeSlotIndexes[answer.selectedTimeSlotIndex];
              if (timeSlotIndex != null && moment(timeSlotIndex.startTime).isSame(this.popoverEvent.start)) {
                let name = '';
                if (answer.concerningProfile != null) {
                  name = Vue.filter('displayProfileNameWithMetadata')(answer.concerningProfile);
                }
                if (!concerningNames.includes(name)) {
                  concerningNames.push(name);
                }
              }
            }
          }
        }
      }
      return concerningNames.join(', ');
    },
    getConcerningInstProfileId() {
      let concerningInstProfileId = '';
      if (this.relatedNotification != null && this.relatedNotification.relatedChildInstitutionProfileId != null) {
        concerningInstProfileId = this.relatedNotification.relatedChildInstitutionProfileId;
      } else if (this.relatedNotification != null && this.relatedNotification.institutionProfileId != null) {
        concerningInstProfileId = this.relatedNotification.institutionProfileId;
      } else if (this.popoverEvent.timeSlot != null) {
        for (const timeSlot of this.popoverEvent.timeSlot.timeSlots) {
          for (const answer of this.getRelevantAnswers(timeSlot)) {
            const timeSlotIndex = timeSlot.timeSlotIndexes[answer.selectedTimeSlotIndex];
            if (timeSlotIndex != null && moment(timeSlotIndex.startTime).isSame(this.popoverEvent.start)) {
              if (answer.concerningProfile != null) {
                concerningInstProfileId = answer.concerningProfile.id;
              }
            }
          }
        }
      }
      return concerningInstProfileId;
    },
    currentAttendee() {
      const invitee = this.popoverEvent.invitees?.find(invitee => this.canHandleEventProfile(invitee.instProfile));
      if (invitee != null) {
        return invitee;
      }

      const isCreator = this.institutions.some(
        institution => institution.institutionProfileId === this.popoverEvent.creator?.id
      );
      if (this.availableGroupHomeChildren.length > 0 && !isCreator) {
        return this.availableGroupHomeChildren[0];
      }

      if (Array.isArray(this.popoverEvent.coOrganizers)) {
        const coOrganizer = this.popoverEvent.coOrganizers.find(
          coOrganizer =>
            this.canHandleEventProfile(coOrganizer.instProfile) &&
            coOrganizer.responseType !== eventResponseStatus.BLOCKED
        );

        if (coOrganizer) {
          return coOrganizer;
        }
      }
      return null;
    },
    isCommunicationBlock() {
      return this.getInstitutionProfile()?.communicationBlock ?? false;
    },
    isEmptyInvitee() {
      return (
        this.popoverEvent.invitees?.length === 0 &&
        this.popoverEvent.inviteeGroups?.length === 0 &&
        this.popoverEvent.invitedGroups?.length === 0 &&
        this.popoverEvent.invitedGroupHomeChildren?.length === 0 &&
        this.popoverEvent.coOrganizers?.length === 0
      );
    },
    canEditEvent() {
      if (this.popoverEvent.type === eventTypeEnum.PRESENCE_HOLIDAY) {
        return false;
      }

      if (this.isEmptyInvitee) {
        return true;
      }

      if (this.isCommunicationBlock) {
        return false;
      }

      if (this.isLesson) {
        return this.getInstitutionProfile() != null;
      } else {
        return this.popoverEvent.type !== eventTypeEnum.PRESENCE_HOLIDAY;
      }
    },
    canDeleteEvent() {
      return (
        this.popoverEvent.type !== eventTypeEnum.LESSON &&
        !this.popoverEvent.isVacationCreatedFromVacationRequest &&
        !this.isChildProfileAndHoliday
      );
    },
    isMeetingEvent() {
      return [
        eventTypeEnum.PARENTAL_MEETING,
        eventTypeEnum.SCHOOL_HOME_MEETING,
        eventTypeEnum.PERFORMANCE_MEETING,
      ].includes(this.popoverEvent.type);
    },
    availableGroupHomeChildren() {
      const invitedGroupHomeChildren = this.popoverEvent.invitedGroupHomeChildren;
      if (!Array.isArray(invitedGroupHomeChildren)) {
        return [];
      }
      const groupHomeChildren = [];
      for (const invitedGroupHomeChild of invitedGroupHomeChildren) {
        const regardingChildId = invitedGroupHomeChild.regardingChildId;
        if (this.children.some(child => child.id === regardingChildId)) {
          groupHomeChildren.push(invitedGroupHomeChild);
        }
      }

      return groupHomeChildren;
    },
  },
  methods: {
    ...mapActions({
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    getInstitutionProfile() {
      return this.institutions.find(institution => institution.institutionCode === this.popoverEvent.institutionCode);
    },
    onSelectedNumberOfParticipants() {
      const numberOfAdultParticipants = this.numberOfAdultParticipants || 0;
      const numberOfChildParticipants = this.numberOfChildParticipants || 0;
      this.isMissingNumberOfParticipants = numberOfAdultParticipants === 0 && numberOfChildParticipants === 0;
      this.hasNumberOfParticipantsChanged = true;
    },
    openDocumentDrawer(data) {
      this.$emit('openDocumentDrawer', data);
    },
    getRelevantAnswers(timeSlot) {
      // Find all answers on the timeSlot that are relevant to current profile
      const existingCalendarProfileIds = this.existingSharedCalendars.map(calendar => parseInt(calendar.singleId));
      return timeSlot.answers.filter(answer => {
        const isConcerningProfile =
          answer.concerningProfile != null &&
          (this.institutions.some(inst => inst.institutionProfileId == answer.concerningProfile.id) ||
            this.children.some(child => child.id == answer.concerningProfile.id) ||
            this.calendarActiveInstProfileId == answer.concerningProfile.id ||
            existingCalendarProfileIds.includes(answer.concerningProfile.id));
        const isInstitutionProfile =
          answer.instProfile != null &&
          (this.institutions.some(inst => inst.institutionProfileId == answer.instProfile.id) ||
            this.calendarActiveInstProfileId == answer.instProfile.id ||
            existingCalendarProfileIds.includes(answer.instProfile.id));
        return isConcerningProfile || isInstitutionProfile;
      });
    },
    getAttendeesName(onlyNames = false) {
      const inviteeNames = [];
      let invitedGroupHomeChildren = this.popoverEvent.invitedGroupHomeChildren || [];

      if (this.isTimeSlotEvent) {
        invitedGroupHomeChildren = this.availableGroupHomeChildren;
      }

      for (const groupHomeChild of invitedGroupHomeChildren) {
        inviteeNames.push({
          name: Vue.filter('groupHomeEventInviteeWithMetaData')(groupHomeChild),
          response: groupHomeChild.responseType,
          numberOfAdultParticipants: groupHomeChild.numberOfAdultParticipants,
          numberOfChildParticipants: groupHomeChild.numberOfChildParticipants,
        });
      }

      if (Array.isArray(this.popoverEvent.invitees)) {
        if (
          this.relatedNotification != null &&
          this.relatedNotification.otherInvitedContactPersons != null &&
          this.relatedNotification.notificationEventType !== notificationEventTypes.SOMEONE_ELSE_RESPONDED_TO_EVENT
        ) {
          for (const invitee of this.relatedNotification.otherInvitedContactPersons) {
            inviteeNames.push({
              name: invitee,
              response: eventResponseStatus.ACCEPTED,
            });
          }
        } else if (this.isMeetingEvent) {
          if (this.popoverEvent.timeSlot != null) {
            for (const timeSlot of this.popoverEvent.timeSlot.timeSlots) {
              for (const answer of this.getRelevantAnswers(timeSlot)) {
                const timeSlotIndex = timeSlot.timeSlotIndexes[answer.selectedTimeSlotIndex];

                if (timeSlotIndex != null && moment(timeSlotIndex.startTime).isSame(moment(this.popoverEvent.start))) {
                  let name = '';

                  if (answer.concerningProfile != null) {
                    name = Vue.filter('displayProfileNameWithMetadata')(answer.concerningProfile);
                  }

                  if (inviteeNames.filter(inv => inv.name == name).length == 0) {
                    inviteeNames.push({
                      name: name,
                      response: eventResponseStatus.ACCEPTED,
                    });
                  }

                  if (
                    this.popoverEvent.creator != null &&
                    answer.instProfile != null &&
                    answer.instProfile.id != this.popoverEvent.creator.id
                  ) {
                    name = Vue.filter('displayProfileNameWithMetadata')(answer.instProfile);
                  }

                  if (inviteeNames.filter(inv => inv.name == name).length == 0) {
                    inviteeNames.push({
                      name: name,
                      response: eventResponseStatus.ACCEPTED,
                    });
                  }
                }
              }
            }
          }
        } else {
          for (const invitee of this.popoverEvent.invitees) {
            let name = '';
            if (invitee.instProfile !== undefined) {
              name = Vue.filter('displayProfileNameWithMetadata')(invitee.instProfile);
            } else {
              name = invitee.name;
            }
            inviteeNames.push({
              name: name,
              response: invitee.responseType,
              numberOfAdultParticipants: invitee.numberOfAdultParticipants,
              numberOfChildParticipants: invitee.numberOfChildParticipants,
            });
          }
        }
        if (onlyNames) {
          return inviteeNames.map(inv => inv.name);
        } else {
          return inviteeNames;
        }
      }

      return inviteeNames.length === 0 ? '' : inviteeNames;
    },
    changeTimeSlotResponse() {
      if (
        this.popoverEvent.type == eventTypeEnum.PARENTAL_MEETING ||
        this.popoverEvent.type == eventTypeEnum.SCHOOL_HOME_MEETING
      ) {
        this.$emit('changeTimeSlotResponse', {
          eventId: this.popoverEvent.id,
          relatedChildInstitutionProfileId: this.getConcerningInstProfileId,
          otherInvitedContactPersons: this.getAttendeesName(true),
        });
      } else if (this.popoverEvent.type == eventTypeEnum.PERFORMANCE_MEETING) {
        this.$emit('changeTimeSlotResponse', {
          eventId: this.popoverEvent.id,
          institutionProfileId: this.getConcerningInstProfileId,
          otherInvitedContactPersons: this.getAttendeesName(true),
        });
      }
    },
    editEvent() {
      this.hide();
      const query = { occurrence: this.occurrenceDateTime };
      calendarUtil.openEditEventDrawer(this.popoverEvent, this.parent, this.$route, this.$router, query);
    },
    editRepeatingSeries() {
      this.hide();
      calendarUtil.openEditEventDrawer(this.popoverEvent, this.parent, this.$route, this.$router);
    },
    isInDelegatedCalendar(resourceId) {
      if (this.myDelegatedAccesses !== undefined) {
        for (const idx in this.myDelegatedAccesses) {
          if (this.myDelegatedAccesses[idx].id === resourceId) {
            return true;
          }
        }
      }
      return false;
    },
    eventToDelete(event, isCurrentEvent = false) {
      const isOccurrenceOnly = this.isRepeatingEvent && isCurrentEvent;
      Vue.nextTick(() => {
        this.onDeleteEventClicked(event, isOccurrenceOnly);
      });
      if (this.currentEvent !== {}) {
        this.$refs.modalPopoverSingleEvent.show();
      } else {
        this.closeClicked();
      }
    },
    respondToEvent(responseType, isToOccurrence = false) {
      if (this.isRequiredParticipantSeats && this.isMissingNumberOfParticipants) {
        return;
      }

      const eventId = this.popoverEvent.id;
      const numberOfAdultParticipants = this.numberOfAdultParticipants;
      const numberOfChildParticipants = this.numberOfChildParticipants;
      let occurrenceDateTime;
      if (isToOccurrence) {
        occurrenceDateTime = this.occurrenceDateTime;
      }
      this.$emit(
        'respondToEvent',
        eventId,
        responseType,
        occurrenceDateTime,
        numberOfAdultParticipants,
        numberOfChildParticipants,
        this.hasNumberOfParticipantsChanged
      );

      this.deleteEventNotifications(eventId, occurrenceDateTime);
      this.closeClicked();
    },
    deleteEventNotifications(eventId, occurrenceDateTime = null) {
      const notifications = this.notifications.filter(
        notification =>
          notification.eventId !== null &&
          notification.eventId == eventId &&
          notification.relatedChildInstitutionProfileId == this.popoverEvent.relatedChildInstitutionProfileId &&
          (!occurrenceDateTime || isSame(occurrenceDateTime, notification.occurrenceDateTime))
      );
      if (notifications.length > 0) {
        this.deleteNotifications({ notifications });
      }
    },
    attachmentClicked(mediaId) {
      if (this.parent == parentTypes.GROUP) {
        this.$router.push({
          name: 'group-calendar-view-media-event',
          params: {
            mediaId: mediaId,
            eventId: this.popoverEvent.id,
            groupId: this.group.id,
          },
        });
      } else {
        this.$router.push({
          name: 'viewMediaEvent',
          params: { mediaId: mediaId, eventId: this.popoverEvent.id },
        });
      }
    },
    closeClicked() {
      this.hide();
      this.$emit('closeClicked');
      if (this.$route.path.includes('/begivenhed/')) {
        if (this.$route.path.includes('/gruppe/')) {
          this.$router.push({
            name: 'group-calendar',
            params: { groupId: this.$route.params.groupId },
          });
        } else {
          this.$router.push({ path: '/kalender' });
        }
      }
    },
    populateEvent() {
      const invitee = this.currentAttendee;
      if (this.isRequiredParticipantSeats && invitee) {
        this.numberOfAdultParticipants = invitee.numberOfAdultParticipants || 0;
        this.numberOfChildParticipants = invitee.numberOfChildParticipants || 0;
      }
    },
    show() {
      this.$refs.modalPopoverSingleEvent.show();
      this.isShown = true;
      this.$nextTick(() => {
        this.populateEvent();
        const popoverEventModalContainer = this.$refs.modalPopoverSingleEvent.$refs.aulaModalContainer;
        popoverEventModalContainer.querySelector('.popover-header_close_icon')?.focus();
      });
    },
    hide() {
      this.$refs.modalPopoverSingleEvent.hide();
      this.isMissingNumberOfParticipants = false;
      this.hasNumberOfParticipantsChanged = false;
      this.numberOfAdultParticipants = null;
      this.numberOfChildParticipants = null;
      this.isShown = false;
    },
    loggedInUserHasProfile(instProfile) {
      return (
        !this.isInDelegatedCalendar(this.calendarActiveInstProfileId) &&
        (this.profile.id == instProfile.id || this.profile.profileId == instProfile.profileId)
      );
    },
    canHandleEventProfile(eventPersonInstProfile) {
      return (
        eventPersonInstProfile.id === parseInt(this.calendarActiveInstProfileId) ||
        this.loggedInUserHasProfile(eventPersonInstProfile)
      );
    },
  },
};
</script>

<style scoped></style>
